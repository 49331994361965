import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Flex, Heading3 } from "@ubisend/pulse-components";

const NoLogoHeader = styled.h4`
  margin: 0px;
  display: flex;
  justify-content: center;
  justify-items: center;
  width: 4rem;
`;

const ClientLogo = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: contain;
`;

const FeatureClientCard = ({ client }) => {
  return (
    <Flex inline xSpace center tall>
      <div>
        {!client.logo && <NoLogoHeader>No Logo</NoLogoHeader>}
        {client.logo && (
          <ClientLogo src={client.logo} alt={`${client.name} logo`} />
        )}
      </div>
      <Flex col ySpace ml>
        <Heading3>{client.name}</Heading3>
      </Flex>
    </Flex>
  );
};

FeatureClientCard.propTypes = {
  client: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string,
  }).isRequired,
};

export default FeatureClientCard;
