import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useHistory } from 'react-router-dom';

import {
  Table,
  Button,
  Tooltip,
  TableRow,
  NoResults,
  TableBody,
  TableCell,
  TableHead,
  Pagination,
  PageWrapper,
  StretchPanel,
  TableHeadCell,
  usePaginationReducer,
  OrderableTableHeadCell,
  useOrderableTableReducer,
} from "@ubisend/pulse-components";
import { useQuery } from "@ubisend/pulse-hooks";
import Icon from "@ubisend/pulse-icons";

dayjs.extend(relativeTime);

const FeaturesLogs = ({ location: { state: { project, account } } }) => {
  const history = useHistory();

  const pagination = usePaginationReducer({ id: "features-logs" });

  const order = useOrderableTableReducer({ id: "features-logs" });

  const queryFeature = useQuery([
    account && account.id
      ? `features/${project.id}/${account.id}`
      : `features/${project.id}/install`
  ]);

  const queryLogs = useQuery([
    account && account.id
      ? `features/${project.id}/${account.id}/logs`
      : `features/${project.id}/install/logs`,
    {
      ...pagination.params,
      ...order.params
    }
  ]);

  return (
    <PageWrapper
      header="Features Logs"
      subheader={
        `View feature logs for ${project && project.name
          ? project.name
          : 'Your Project'
        } - ${account && account.name
          ? account.name
          : 'All Install'
        }`
      }
      actions={
        <Button
          icon="arrowNarrowLeft"
          variant="primary"
          onClick={() => history.goBack()}>
          Back
        </Button>
      }
    >
      <StretchPanel>
        {
          (queryLogs.isSuccess && queryLogs.data.data.length > 0
            ?
            <Table loading={false} loadingColumns={4}>
              <TableHead>
                <TableRow>
                  <OrderableTableHeadCell
                    row={{
                      label: "User",
                      sort: "users.email",
                    }}
                    {...order.props}
                  />
                  <TableHeadCell>Feature</TableHeadCell>
                  <TableHeadCell>Request</TableHeadCell>
                  <OrderableTableHeadCell
                    row={{
                      label: "When",
                      sort: "created_at",
                    }}
                    {...order.props}
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  queryLogs.data.data.map((log, key) => (
                    <TableRow key={key}>
                      <TableCell>
                        <div>{log.userEmail}</div>
                      </TableCell>
                      <TableCell>
                        <div>{
                          queryFeature.isSuccess
                            && queryFeature.data.data.filter(feature => feature.id === log.featureId).length > 0
                            ? queryFeature.data.data.filter(feature => feature.id === log.featureId)[0]['name']
                            : (
                              <div>
                                {log.featureId}
                                <Tooltip
                                  position={Tooltip.POSITIONS.RIGHT}
                                  tooltip={
                                    <Tooltip.Content>
                                      Unable to featch the name, perhaps the feature name is removed
                                    </Tooltip.Content>
                                  }
                                >
                                  <Icon
                                    stroke="red"
                                    size="1rem"
                                    width="1rem"
                                    height="1rem"
                                    type="exclamation"
                                    outline
                                    style={{ cursor: "auto" }}
                                  />
                                </Tooltip>
                              </div>
                            )
                        }</div>
                      </TableCell>
                      <TableCell>
                        <div>{log.request ? 'activated' : 'deactivated'}</div>
                      </TableCell>
                      <TableCell>
                        <div>{dayjs(log.createdAt).format("DD/MM/YYYY HH:mm:ss")}</div>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
            :
            <NoResults />
          )
        }
        {queryLogs.showPagination && (
          <Pagination pagination={queryLogs.data.meta} {...pagination.props} />
        )}
      </StretchPanel>
    </PageWrapper >
  );
};

export default FeaturesLogs;
