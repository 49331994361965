import React from "react";
import PropTypes from "prop-types";

import {
  Flex,
  TextInput as Input,
  Label,
  Accordion,
} from "@ubisend/pulse-components";

const InfoPanel = ({ header, content }) => (
  <Flex col mb>
    <Label htmlFor={header}>{header}</Label>
    <Input id={header} readOnly value={content} />
  </Flex>
);

InfoPanel.propTypes = {
  header: PropTypes.any,
  content: PropTypes.any,
};

const ProjectInfo = ({ project }) => {
  const {
    app_distribution_id,
    app_distribution_url,
    app_origin,
    app_repo,
    api_repo,
    api_url,
    bot_server_repo,
    bot_server_url,
  } = project;

  return (
    <>
      <Flex mb col>
        <Accordion header="App Info" open>
          <InfoPanel
            header="App Distribution ID"
            content={app_distribution_id}
          />
          <InfoPanel
            header="App Distribution URL"
            content={app_distribution_url}
          />
          <InfoPanel header="App Origin" content={app_origin} />
          <InfoPanel header="App Repo" content={app_repo} />
        </Accordion>
      </Flex>
      <Flex mb col>
        <Accordion header="Api Info">
          <InfoPanel header="Api Repo" content={api_repo} />
          <InfoPanel header="Api URL" content={api_url} />
        </Accordion>
      </Flex>
      <Flex mb col>
        <Accordion header="Bot Server Info">
          <InfoPanel header="Bot Server Repo" content={bot_server_repo} />
          <InfoPanel header="Bot Server URL" content={bot_server_url} />
        </Accordion>
      </Flex>
    </>
  );
};

ProjectInfo.propTypes = {
  project: PropTypes.any,
};

export default ProjectInfo;
