import React, { useState } from "react";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { AnimatePresence } from "@ubisend/framer-motion";
import {
  PageWrapper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Flex,
  StretchPanel,
  TableHeadCell,
  NoResults,
  Button,
  PanelSlider,
} from "@ubisend/pulse-components";
import Icon from "@ubisend/pulse-icons";

import { UserDetails } from "./index";

dayjs.extend(relativeTime);

const Report = ({ match: { params } }) => {
  const [showUsers, setShowUsers] = useState(false);

  const { data, isLoading } = useQuery(`reports/${params.id}`);

  return (
    <PageWrapper header={"Reporting"}>
      <StretchPanel>
        <Table loading={isLoading} loadingColumns={6}>
          {!isLoading && data && (
            <>
              {!data.clients.length === 0 && <NoResults />}
              {data.clients.length > 0 && (
                <TableHead>
                  <TableRow>
                    <TableHeadCell>Client Name</TableHeadCell>
                    <TableHeadCell>Client Email</TableHeadCell>
                    <TableHeadCell
                      row={{ label: "Number of users", sort: "users_total" }}
                    />
                    <TableHeadCell>Country</TableHeadCell>
                    <TableHeadCell>Created At</TableHeadCell>
                    <TableHeadCell>Actions</TableHeadCell>
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {data.clients.map(
                  ({ country, email, name, users, created_at }, key) => (
                    <TableRow key={key}>
                      <TableCell>{name}</TableCell>
                      <TableCell>{email}</TableCell>
                      <TableCell>{users.length}</TableCell>
                      <TableCell>{country || "N/A"}</TableCell>
                      <TableCell>{`${dayjs(created_at)}`}</TableCell>
                      <TableCell>
                        <Flex>
                          <Button
                            variant="secondary"
                            disabled={users.length === 0}
                            onClick={() => setShowUsers(users)}
                          >
                            <Flex xSpaceSm center>
                              <Icon outline type="eye" />
                              <span>Users</span>
                            </Flex>
                          </Button>
                        </Flex>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </>
          )}
        </Table>
      </StretchPanel>
      <AnimatePresence>
        {showUsers && (
          <PanelSlider
            header="View users"
            handleHide={() => setShowUsers(false)}
          >
            <UserDetails users={showUsers} />
          </PanelSlider>
        )}
      </AnimatePresence>
    </PageWrapper>
  );
};

export default Report;
