import React, { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useMutation, useQueryClient } from "react-query";

import {
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Flex,
  Button,
  Label,
  TextInput,
  FormGroup,
  DateTimePicker,
} from "@ubisend/pulse-components";

import { editLicense as editLicenseApi } from "../api/index";

const EditLicense = ({
  license: intialLicense,
  project_id,
  handleCancel,
}) => {
  const [license, setLicense] = useState({
    project_id: project_id,
    license_id: intialLicense.license_id,
    end: intialLicense.period.end,
    start: intialLicense.period.start,
    set_to_expire: intialLicense.period.end !== null,
  });

  const queryClient = useQueryClient();

  const handleChangeStart = ([date]) => setLicense({ ...license, start: date });
  const handleChangeEnd = ([date]) => setLicense({ ...license, end: date });

  const { mutate: editLicense } = useMutation(editLicenseApi, {
    onSuccess: () => {
      handleCancel(false);
      queryClient.invalidateQueries(`licenses/${license.project_id}`);
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    editLicense({
      project_id: license.project_id,
      license_id: license.license_id,
      start: license.start,
      end: license.end,
    });
  };

  return (
    <ModalPortal>
      <form onSubmit={handleSubmit}>
        <ModalContainer>
          <ModalContent>
            <ModalHeader>Edit an existing license</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label>License valid from</Label>
                <DateTimePicker
                  date={license.start}
                  onChange={handleChangeStart}
                />
              </FormGroup>
              <FormGroup>
                <Label>License expires on</Label>
                {!license.set_to_expire && (
                  <Flex xSpace>
                    <TextInput disabled value="Never Expire" />
                    <Button
                      onClick={() =>
                        setLicense({
                          ...license,
                          set_to_expire: true,
                          end: dayjs().add(1, "year").toDate(),
                        })
                      }
                    >
                      Change
                    </Button>
                  </Flex>
                )}
                {license.set_to_expire && (
                  <Flex xSpace>
                    <DateTimePicker
                      date={license.end}
                      onChange={handleChangeEnd}
                    />
                    <Button
                      onClick={() =>
                        setLicense({
                          ...license,
                          set_to_expire: false,
                          end: null,
                        })
                      }
                    >
                      Make Infinite
                    </Button>
                  </Flex>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Flex xSpace>
                <Button variant="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </ModalContainer>
      </form>
    </ModalPortal>
  );
};

EditLicense.propTypes = {
  project: PropTypes.any,
  submit: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default EditLicense;
