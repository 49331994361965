import React, { useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useHistory } from 'react-router-dom';

import {
  PageWrapper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeadCell,
  TableCell,
  StretchPanel,
  Button,
  NoResults,
  Span,
  Flex,
  useModal,
} from "@ubisend/pulse-components";
import { useQuery, useMutation, useQueryClient } from "@ubisend/pulse-hooks";

import { CreateLicense, EditLicense, LicenseClientCard } from "../Components/index";
import { deleteLicense as deleteLicenseApi } from "../api/index";

dayjs.extend(relativeTime);

const Licenses = ({ match: { params }, location: { state: name } }) => {
  const history = useHistory();

  const [createLicense, setCreateLicense] = useState(false);

  const [editLicense, setEditLicense] = useState(false);

  const { showModal, hideModal } = useModal();

  const queryClient = useQueryClient();

  const query = useQuery([`licenses/${params.projectId}`]);

  const handleExpiry = ({ status, period }) => {
    if (status && !period?.end) {
      return <Span>Never expires</Span>;
    }

    if (period?.end) {
      return (
        <Flex col>
          <Span>{dayjs(period.end).format("DD/MM/YYYY")}</Span>
          <Span light>{dayjs(period.end).from(dayjs())}</Span>
        </Flex>
      );
    }
  };

  const { mutate: deleteLicense } = useMutation(deleteLicenseApi);

  const handleDestroy = (license) => {
    showModal({
      header: "Delete a license",
      body: `Are you sure you want to delete this license? If it's linked to a client, their bots will immediately stop working. Make sure you know what you're doing.`,
      handleConfirm: async () => {
        try {
          deleteLicense({
            project_id: params.projectId,
            license_id: license.license_id,
          });

          queryClient.invalidateQueries(`licenses/${params.projectId}`);
        } finally {
          hideModal();
        }
      },
    });
  };

  return (
    <>
      <PageWrapper
        header="Licenses"
        subheader={`Manage licenses for ${name?.name ?? "your install"}`}
        actions={
          <Button
            icon="arrowNarrowLeft"
            variant="primary"
            onClick={() => history.goBack()}>
            Back
          </Button>
        }
      >
        {createLicense && (
          <CreateLicense
            account_id={createLicense}
            project_id={params.projectId}
            handleCancel={() => setCreateLicense(false)}
          />
        )}

        {editLicense && (
          <EditLicense
            project_id={params.projectId}
            license={editLicense}
            handleCancel={() => setEditLicense(false)}
          />
        )}

        {query.isSuccess && query.data.data.length === 0 && (
          <NoResults
            text="No projects found"
            subtitle="Why not create a shiny new one"
          />
        )}

        {query.isError && (
          <NoResults
            text="There was a problem connecting to the project"
            subtitle="Double check the projects credentials or beg a developer for help"
          />
        )}

        {query.isSuccess && query.data.data.length > 0 && (
          <StretchPanel>
            <Table loading={query.isLoading} loadingColumns={4}>
              <TableHead>
                <TableRow>
                  <TableHeadCell>Account information</TableHeadCell>
                  <TableHeadCell>Exact expiry</TableHeadCell>
                </TableRow>
              </TableHead>
              {!query.isLoading && query.data?.data.length > 0 && (
                <TableBody>
                  {query.data.data.map((license, key) => (
                    <TableRow key={key}>
                      <TableCell>
                        <LicenseClientCard
                          client={{
                            logo: null,
                            name: license.account_name,
                            license,
                          }}
                        />
                      </TableCell>
                      <TableCell>{handleExpiry(license)}</TableCell>
                      <TableCell>
                        {!license.status && (
                          <Flex xSpace right>
                            <Button
                              onClick={() => setCreateLicense(license.account_id)}
                            >
                              + Add license
                            </Button>
                          </Flex>
                        )}
                        {license.status && (
                          <Flex xSpace right>
                            <Button onClick={() => setEditLicense(license)}>
                              Manage
                            </Button>
                            <Button
                              variant="secondary"
                              colour="danger"
                              icon="trash"
                              onClick={() => handleDestroy(license)}
                            >
                              Remove
                            </Button>
                          </Flex>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </StretchPanel>
        )}
      </PageWrapper>
    </>
  );
};

export default Licenses;
