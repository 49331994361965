import React from "react";
import { Switch } from "react-router-dom";
import { Login, Logout } from "../Pages/Auth/index";

import {
  UsersPage,
  ReportPage,
  ReportsPage,
  AcceptInvite,
  FeaturesPage,
  LicensesPage,
  ProjectsPage,
  CreateProject,
  PasswordReset,
  PasswordRequest,
  FeaturesLogsPage,
  FeaturesManagePage,
} from "../Pages/index";
import Route from "./Route";

const authRoutes = [
  {
    name: "Login",
    path: "/",
    component: Login,
    public: true,
  },
  {
    name: "Login",
    path: "/login",
    component: Login,
    public: true,
  },
  {
    name: "Logout",
    path: "/logout",
    component: Logout,
  },
  {
    name: "Request New Password",
    path: "/password/request",
    component: PasswordRequest,
    public: true,
  },
  {
    name: "Reset Password",
    path: "/password/reset",
    component: PasswordReset,
    public: true,
  },
  {
    name: "Accept Invite",
    path: "/invites/accept",
    component: AcceptInvite,
    public: true,
  },
];

const nonAuthRoutes = [
  {
    name: "Home",
    path: "/home",
    component: ProjectsPage,
  },
  {
    path: "/dashboard",
    component: ProjectsPage,
  },
  {
    path: "/licenses/:projectId",
    component: LicensesPage,
  },
  {
    path: "/projects/create",
    component: CreateProject,
  },
  {
    path: "/reports",
    component: ReportsPage,
  },
  {
    path: "/reports/:id/report",
    component: ReportPage,
  },
  {
    path: "/users",
    component: UsersPage,
  },
  {
    path: "/features/:projectId",
    component: FeaturesPage,
  },
  {
    path: "/features/:projectId/:clientId",
    component: FeaturesManagePage,
  },
  {
    path: "/features/:projectId/:clientId/logs",
    component: FeaturesLogsPage,
  },
];

const routes = [...nonAuthRoutes, ...authRoutes];

const Routes = () => (
  <Switch>
    {routes.map((route, key) => (
      <Route
        exact
        public={route.public}
        key={key}
        path={route.path}
        render={(props) => {
          return (
            <>
              <route.component
                {...props}
                {...(route.slots && {
                  slots: route.slots,
                })}
              />
            </>
          );
        }}
      />
    ))}
  </Switch>
);

export default Routes;
export { routes, nonAuthRoutes };
