import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Flex, Heading3, Button } from "@ubisend/pulse-components";

const NoLogoHeader = styled.h4`
  margin: 0px;
  display: flex;
  justify-content: center;
  justify-items: center;
  width: 4rem;
`;

const ClientLogo = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: contain;
`;

const ActiveStatus = () => {
  return (
    <Flex left>
      <Button style={{ paddingLeft: "0" }} variant="inline" colour="positive">
        Active license
      </Button>
    </Flex>
  );
};

const ExpiringStatus = () => {
  return (
    <Flex left>
      <Button style={{ paddingLeft: "0" }} variant="inline" colour="warning">
        License Expiring
      </Button>
    </Flex>
  );
};

const ExpiredStatus = () => {
  return (
    <Flex col left>
      <Button style={{ paddingLeft: "0" }} variant="inline" colour="danger">
        License Expired
      </Button>
    </Flex>
  );
};

const NoLicense = () => {
  return (
    <Flex left>
      <Button style={{ paddingLeft: "0" }} variant="inline" colour="danger">
        No Attached License
      </Button>
    </Flex>
  );
};

const statuses = {
  active: ActiveStatus,
  expired: ExpiredStatus,
  expiring: ExpiringStatus,
};

const License = ({ license }) => {
  if (!license.status) {
    return <NoLicense />;
  }

  const Component = statuses[license.status];

  return <Component {...license} />;
};

License.propTypes = {
  license: PropTypes.shape({
    status: PropTypes.oneOf(Object.keys(statuses)),
  }),
};

const LicenseClientCard = ({ client }) => {
  return (
    <Flex inline xSpace center tall>
      <div>
        {!client.logo && <NoLogoHeader>No Logo</NoLogoHeader>}
        {client.logo && (
          <ClientLogo src={client.logo} alt={`${client.name} logo`} />
        )}
      </div>
      <Flex col ySpace ml>
        <Heading3>{client.name}</Heading3>
        <License license={client.license} />
      </Flex>
    </Flex>
  );
};

LicenseClientCard.propTypes = {
  client: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string,
    license: PropTypes.object,
  }).isRequired,
};

export default LicenseClientCard;
