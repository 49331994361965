import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from 'react-router-dom';

import {
  Flex,
  Label,
  Table,
  Button,
  Checkbox,
  TableRow,
  Explainer,
  ModalBody,
  NoResults,
  TableBody,
  TableCell,
  TableHead,
  ModalFooter,
  ModalHeader,
  ModalPortal,
  PageWrapper,
  ModalContent,
  StretchPanel,
  TableHeadCell,
  ModalContainer,
  useNotification
} from "@ubisend/pulse-components";

import { featureToggle as featureToggleApi } from "../api";

const FeaturesManage = ({ location: { state: { account, project } } }) => {
  const history = useHistory();

  const query = useQuery([
    account
      ? `features/${project.id}/${account.id}`
      : `features/${project.id}/install`
  ]);

  const queryClient = useQueryClient();

  const { showError, showSuccess } = useNotification();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [featureId, setFeatureId] = useState(null);

  const [featureName, setFeatureName] = useState('');

  const [featureState, setFeatureState] = useState(null);

  const { mutate: featureToggle } = useMutation(
    featureToggleApi, {
    onSuccess: () => {
      showSuccess(`feature '${featureName}' has been ${featureState ? 'disabled' : 'enabled'} successfully.`);
      setFeatureId(null);
      setFeatureName('');
      setFeatureState(null);
      setShowConfirmModal(false);
      queryClient.invalidateQueries(
        account
          ? `features/${project.id}/${account.id}`
          : `features/${project.id}/install`
      );
    },
    onError: () => {
      showError(`feature '${featureName}' has not been ${featureState ? 'disabled' : 'enabled'} successfully.`);
      setShowConfirmModal(false);
    },
  });

  const handleCloseModal = () => {
    setShowConfirmModal(false);
  };

  const handleOpenModal = (data_id, data_name, data_state) => {
    setFeatureId(data_id);
    setFeatureName(data_name);
    setFeatureState(data_state);
    setShowConfirmModal(true);
  };

  const handleSubmit = () => {
    featureToggle({
      projectId: project.id,
      featureId: featureId,
      state: !featureState,
      clientId: account ? account.id : 'install'
    });
  };

  return (
    <PageWrapper
      header="Features"
      subheader={
        `Manage features for ${project && project.name
          ? project.name
          : 'Your Project'
        } - ${account && account.name
          ? account.name
          : 'All Install'
        }`}
      actions={
        <Button
          icon="arrowNarrowLeft"
          variant="primary"
          onClick={() => history.goBack()}>
          Back
        </Button>
      }
    >
      <StretchPanel>
        {
          showConfirmModal
          && (
            <ModalPortal>
              <ModalContainer>
                <ModalContent>
                  <ModalHeader>Confirm {featureState ? 'disabling' : 'enabling'} {featureName}</ModalHeader>
                  <ModalBody>
                    <Explainer type={featureState ? 'danger' : 'note'}>
                      You are about to <b>{
                        featureState
                          ? 'disable'
                          : 'enable'
                      }</b> feature <b>"{
                        featureName
                      }"</b> in <b>{
                        project && project.name
                          ? project.name
                          : 'Your Project'
                      }</b>.
                      Are you sure you want to continue?
                    </Explainer>
                  </ModalBody>
                  <ModalFooter>
                    <Flex right>
                      <Flex xSpace>
                        <Button variant="secondary" onClick={handleCloseModal}>
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          onClick={handleSubmit}>
                          Yes
                        </Button>
                      </Flex>
                    </Flex>
                  </ModalFooter>
                </ModalContent>
              </ModalContainer>
            </ModalPortal>
          )
        }
        {
          (query.isSuccess && query.data.data.length > 0)
            ?
            <Table loading={query.isLoading} loadingColumns={4}>
              <TableHead>
                <TableRow>
                  <TableHeadCell>Feature Name</TableHeadCell>
                  <TableHeadCell>State</TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {query.data.data.map((feature, key) => (
                  <TableRow key={key}>
                    <TableCell >
                      <Label>
                        {feature.name}
                      </Label>
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        aria-label={`feature-select-${feature.id}`}
                        checked={feature.state}
                        onChange={
                          () => handleOpenModal(
                            feature.id,
                            feature.name,
                            feature.state
                          )
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            :
            <NoResults />
        }
      </StretchPanel>
    </PageWrapper >
  );
};

export default FeaturesManage;
