import axios from "axios";

const featureToggle = ({ projectId, featureId, state, clientId }) => {
  axios.put(`features/toggle/${projectId}/${clientId}/${featureId}`,
    {
      project_id: projectId,
      client_id: clientId,
      feature_id: featureId,
      updated_value: state
    }
  );
};

export { featureToggle };
