import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';

import {
  Flex,
  Table,
  Button,
  Heading3,
  TableRow,
  NoResults,
  TableBody,
  TableCell,
  TableHead,
  PageWrapper,
  StretchPanel,
  TableHeadCell,
} from "@ubisend/pulse-components";

import { FeatureClientCard } from "../Components/index";
import Icon from "@ubisend/pulse-icons";
import { useQuery } from "@ubisend/pulse-hooks";

const Features = ({ location: { state: { name, project } } }) => {
  const history = useHistory();

  const query = useQuery([`features/${project.project_id}`]);

  return (
    <PageWrapper
      header="Features"
      subheader={`Manage features for ${name?.name ?? "your install"}`}
      actions={
        <Button
          icon="arrowNarrowLeft"
          variant="primary"
          onClick={() => history.goBack()}>
          Back
        </Button>
      }
    >
      <StretchPanel>
        {
          query.isSuccess
            ?
            <Table loading={query.isLoading} loadingColumns={4}>
              <TableHead>
                <TableRow>
                  <TableHeadCell>Account information</TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={0}>
                  <TableCell>
                    <Heading3>Install Level Feature Setting</Heading3>
                  </TableCell>
                  <TableCell >
                    <Flex xSpace right>
                      <Button
                        as={Link}
                        to={{
                          pathname: `/features/${project.project_id}/install/logs`,
                          state: {
                            project: { name: project.name, id: project.project_id },
                            account: null
                          }
                        }}
                      >
                        <Flex xSpaceSm>
                          <Icon outline type="eye" />
                          <span>View Logs</span>
                        </Flex>
                      </Button>
                      <Button
                        as={Link}
                        to={{
                          pathname: `/features/${project.project_id}/install`,
                          state: {
                            project: { name: project.name, id: project.project_id },
                            account: null
                          }
                        }}
                      >
                        <Flex xSpaceSm>
                          <Icon outline type="key" />
                          <span>Edit Features</span>
                        </Flex>
                      </Button>
                    </Flex>
                  </TableCell>
                </TableRow>
                {query.data.data.map((account) => (
                  <TableRow key={account.account_id}>
                    <TableCell>
                      <FeatureClientCard
                        client={{
                          logo: null,
                          name: account.account_name,
                          account,
                        }}
                      />
                    </TableCell>
                    <TableCell >
                      <Flex xSpace right>
                        <Button
                          as={Link}
                          to={{
                            pathname: `/features/${project.project_id}/${account.account_id}/logs`,
                            state: {
                              project: { name: project.name, id: project.project_id },
                              account: { name: account.account_name, id: account.account_id }
                            }
                          }}
                        >
                          <Flex xSpaceSm>
                            <Icon outline type="eye" />
                            <span>View Logs</span>
                          </Flex>
                        </Button>
                        <Button
                          as={Link}
                          to={{
                            pathname: `/features/${project.project_id}/${account.account_id}`,
                            state: {
                              project: { name: project.name, id: project.project_id },
                              account: { name: account.account_name, id: account.account_id }
                            }
                          }}
                        >
                          <Flex xSpaceSm>
                            <Icon outline type="key" />
                            <span>Edit Features</span>
                          </Flex>
                        </Button>
                      </Flex>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            :
            <NoResults />
        }
      </StretchPanel>
    </PageWrapper>
  );
};

export default Features;
